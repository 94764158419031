export default {
  acceptedInvitation: "Accepted",
  account: "Account",
  userSettings: "User Settings",
  actions: "Actions",
  active: "Active",
  activity: "Activity",
  add: "Add",
  addAnother: "Add Another",
  addChangelog: "Add Changelog",
  addContributors: "Add Contributor(s)",
  addCustomMessage: "Add Custom Message?",
  addIdea: "Add Idea",
  addIdeaOrQuestion: "Add Idea or Question",
  additionalOptions: "Additional Options",
  addNewCategory: "Add New Category",
  addTag: "Add a tag",
  addTags: "Add Tags",
  addVisualsAndMedia: "Add Visuals & Media",
  addWorkspaceMember: "Add Workspace Member",
  administration: "Admin",
  administrator: "Admin",
  adminSettings: "Admin",
  all: "All",
  allCategoriesAreRemovedFromSelectedIdeas: "All categories are removed from selected ideas",
  allChallenges: "All",
  allIdeas: "All Ideas",
  allTime: "All Time",
  anonymous: "Anonymous",
  anyAttachments: "Any attachments?",
  archive: "Archive",
  archiveChallenge: "Archive Challenge",
  archived: "Archived",
  archivedChallenges: "Archive",
  archiveUser: "Archive User",
  archivingChallenge: "Archiving challenge will prevent respondents from submitting new idea and accessing this page.",
  areYouSureToDeleteChallenge: "Are you sure you want to delete the challenge?",
  areYouSureToDeleteIdea: "Are you sure you want to delete the idea?",
  areYouSureYouWantToDeleteChangelog: "Are you sure you want to delete the changelog?",
  areYouSureYouWantToDeleteXIdea: "Are you sure you want to delete {{count}} selected idea?",
  areYouSureYouWantToDeleteXIdea_plural: "Are you sure you want to delete {{count}} selected ideas?",
  areYouSureYouWantToDeleteXMember: "Are you sure you want to delete {{count}} selected member?",
  areYouSureYouWantToDeleteXMember_plural: "Are you sure you want to delete {{count}} selected members?",
  askQuestion: "Ask a Question",
  assign: "Assign",
  assignedXToName: "Assigned {{count}} idea to {{name}}",
  assignedXToName_plural: "Assigned {{count}} ideas to {{name}}",
  assignToCategory: "Assign to Category",
  assignToNewCategory: "Assign to New Category",
  associatedXTagToSelectedIdeas: "Associated {{count}} tag to selected idea(s).",
  associatedXTagToSelectedIdeas_plural: "Associated {{count}} tags to selected idea(s).",
  attachments: "Attachments",
  attachmentsCanHelp: "Visual artifacts can help help the innovation process.",
  autoEvaluation: "Auto Evaluation",
  availableTeams: "Available Teams",
  avoidCommonPassword: "Avoid common password",
  avoidLowContrast: "For a better experience avoid low contrast between text and backgrounds for every element.",
  awards: "Awards",
  back: "Back",
  backgroundColor: "Background",
  beTheFirstToVote: "Be the first to vote",
  brand: "Brand",
  cancel: "Cancel",
  categories: "Categories",
  category: "Category",
  categoryName: "Category name",
  categoryNameIsRequired: "Category name is required",
  challenge: "Challenge",
  challengeDetails: "Challenge Details",
  challengeParticipatedIn: "Challenges participated in",
  challengePrivateExplanation: "Challenge not visible on team dashboard, only invitees see and participate the challenge.",
  challenges: "Challenges",
  challengeTeamExplanation: "Challenge visible on team dashboard, only invitees receive email notifications.",
  changelog: "Changelog",
  changePassword: "Change Password",
  changeRole: "Change Role",
  chooseALevelOfVisibility: "Choose a level of visibility for your survey and participants will be invited via email.",
  clear: "Clear",
  clearFilters: "Clear Filters",
  clickToSeeMoreDetails: "Click to see more details",
  clickToSeeYourChallenges: "Click to see your challenges",
  close: "Close",
  code: "Code",
  collaborationStage: "Collaboration",
  completedChallenges: "Completed",
  completedOn: "Completed on",
  completedStage: "Completed",
  confirm: "Confirm",
  confirmDeleteChallengeComment: "Are you sure you want to delete this comment? All the associated replies will be deleted.",
  confirmDeleteIdeaComment: "Are you sure you want to delete this comment?",
  contrastIsTooLow: "Try increasing the contrast between foreground and background colors to improve legibility.",
  contributions: "Contributions",
  contributor: "Contributor",
  contributors: "Contributors",
  contributorsGroup: "Contributors group",
  createChallenge: "Create a Challenge",
  createWorkspace: "Create Workspace",
  custom: "Custom",
  customDomain: "Custom Domain",
  customDomainAllowsYouTo: "The custom domain allows you to use a completely custom domain name.",
  customizeTheme: "Customize Theme",
  customMessage: "Custom Message",
  customSubdomain: "Custom Subdomain",
  customSubdomainWillBe: "The custom subdomain will appear in the address bar of browsers.",
  dashboard: "Dashboard",
  date: "Date",
  day: "day",
  days: "days",
  default: "Default",
  defaultWorkspace: "Default Workspace",
  delete: "Delete",
  deleteAccount: "Delete Account",
  deleteCategory: "Delete Category",
  deleteChallenge: "Delete Challenge",
  deleteChangelog: "Delete Changelog",
  deleteComment: "Delete Comment",
  deleteIdea: "Delete Idea",
  deleteMember: "Delete Member",
  deleteUser: "Delete User",
  deleteWorkspace: "Delete Workspace",
  deleteXCategory: 'Delete "{{name}}" category',
  deletingYourAccountWillBePermanent: "Deleting your account will be permanent",
  describeChallenge: "Describe the background, constraints, and objectives of your challenge.",
  describeItInMoreDetail: "Describe it in more detail",
  descriptionIsRequired: "The description is required",
  design: "Design",
  discussFurther: "Discuss Further",
  discussions: "Discussions",
  documents: "Documents",
  draft: "Draft",
  drafts: "Drafts",
  draftStage: "Draft",
  dropFileHere: "Drop a file here or click to upload",
  dropFilesHere: "Drop files here or click to upload",
  edit: "Edit",
  editCategory: "Edit category",
  editChallenge: "Edit challenge",
  editIdea: "Edit idea",
  editTiming: "Edit timing",
  email: "Email",
  emailIsRequired: "Email is required",
  end: "End",
  endsWhenYouSave: "Ends when you save",
  engagement: "Engagement",
  english: "English",
  enterChallengeDescription: "Enter challenge description",
  enterChallengeTitle: "Enter challenge title",
  enterDescription: "Enter description",
  enterEmailHere: "Enter email here",
  enterMessage: "Enter message",
  enterNameHere: "Enter name here",
  enterNewCategoryName: "Enter new category name here...",
  enterNewCategoryNameHere: "Enter new category name here",
  enterNewWorkspaceHere: "Enter new workspace name here",
  enterTitle: "Enter title",
  error: "Error",
  errorOccurred: "An error has occurred",
  evaluationStage: "Evaluation",
  existingTagsHaveBeenRemoved: "Existing tags have been removed from selected ideas.",
  explainReward: "Explain the reward",
  extend: "Extend",
  favorites: "Favorites",
  fieldIsRequired: "This field is required.",
  fileExceedsSizeLimit: "The uploaded file exceed the file size limit",
  filterByContributions: "Contributions",
  filterByTags: "Tags",
  filterByTeam: "Team",
  findAvailableMediaTypes: "Find a media type",
  fix: "Fix",
  forgotPassword: "Forgot Password?",
  fullLogo: "Full Logo",
  fullTeam: "Full Team",
  generalSettings: "General",
  givePraise: "Give Praise",
  googleImageSearch: "Google Image Search",
  grantWorkspaceAdmin: "Grant workspace admin",
  groups: "Groups",
  guest: "Guest",
  help: "Help",
  hours: "hours",
  idea: "Idea",
  ideas: "Ideas",
  ideasWillBeUnassigned: "All the ideas in this category will still exist, but will be unassigned.",
  ideationStage: "Ideation",
  ideaWasImportedFromAChallenge: "This idea was imported from a challenge",
  ideaWasSentDirectly: "This idea was sent directly",
  ideaWasSentViaApp: "This idea was sent via Invent Value mobile app",
  ideaWasSentViaEmail: "This idea was sent via email",
  ideaWasSentViaSlack: "This idea was sent via Slack",
  images: "Images",
  improvement: "Improvement",
  inactive: "Inactive",
  insert: "Insert",
  invalidEmailAddress: "Invalid email address",
  invitationMessage: "Invitation Message",
  inviteParticipantAsGuest: "Invite this participant as a guest?",
  invitePeople: "Invite People",
  inviteX: "Invite {{name}}",
  japanese: "Japanese",
  leaveWorkspace: "Leave Workspace",
  letsCreateFirstWorkspace: "Let’s create your first workspace!",
  linkedIdeas: "Linked Ideas",
  linkIdea: "Link Idea",
  linkToIdeas: "Link to Idea(s)",
  linkToXIdea: "Link to {{count}} idea",
  linkToXIdea_plural: "Link to {{count}} ideas",
  listView: "List View",
  live: "Live",
  liveChallenges: "Live",
  loading: "Loading...",
  loadMore: "Load More",
  logo: "Logo",
  logout: "Logout",
  makeDefault: "Make Default",
  makeSureValidTimes: "Please make sure all challenge times are valid",
  maxUploadFileSize: "Maximum upload file size",
  medium: "Medium",
  member: "Member",
  members: "Members",
  menu: "Menu",
  menuTextColor: "Menu Text",
  minute: "minute",
  minutes: "minutes",
  missingName: "Missing Name",
  moderators: "Moderators",
  month: "Month",
  months: "months",
  mostActivities: "Most Activities",
  mostActivity: "Most Activity",
  mostContributions: "Most Contributions",
  mostIdeasContributed: "Most Ideas Contributed",
  mostPraiseReceived: "Most Praise Received",
  mostRecent: "Most Recent",
  mostVotes: "Most Votes",
  moveToNextStage: "Move to next stage",
  moveToPreviousStage: "Move to previous stage",
  multipleContributors: "Multiple contributors",
  mustBeLessThanXCharactersLong: "Must be less than {{ count }} characters long",
  mustBeValidDomain: "Must be a valid domain",
  name: "Name",
  nameIsRequired: "Name is required",
  navBar: "Nav Bar",
  new: "New",
  newChangelog: "New Changelog",
  newPassword: "New Password",
  newPasswordIsRequired: "New password is required",
  next: "Next",
  noChallenges: "No challenges",
  noQualifyingTopContributions: "No qualifying top contributions",
  noQualifyingTopIdeas: "No qualifying top ideas",
  noSearchResults: "No result, try another keyword",
  notApplicable: "Not Applicable",
  notifications: "Notifications",
  off: "OFF",
  oldest: "Oldest",
  oldPassword: "Old Password",
  on: "ON",
  openMenu: "Open menu",
  optional: "Optional",
  other: "Other",
  otherFilters: "Other Filters",
  pageDoesNotExist: "The requested page does not exist",
  participants: "Participants",
  participantWillBeInvitedAsGuest: "This participant does not belong to the workspace. You can still invite the person as a guest for this challenge.",
  password: "Password",
  passwordIsRequired: "Password is required",
  passwordStrength: "Password Strength",
  pendingInvitation: "Pending",
  peopleAreEngagingWithChallenge: "People are engaging with this challenge",
  peopleAreEngagingWithComment: "People are engaging with this comment",
  pleaseEnterValidURL: "Please enter valid URL",
  pleaseEnterValidXURL: "Please enter a valid {{name}} URL below",
  pleaseEnterValidYouTube: "Please enter a keyword or a valid YouTube URL below",
  pleaseTryAgainLater: "Please try again later",
  pointsReceived: "Points received",
  post: "Post",
  praise: "Praise",
  praises: "Praises",
  preferredLanguage: "Preferred Language",
  preview: "Preview",
  privacy: "Privacy",
  private: "Private",
  process: "Process",
  profile: "Profile",
  profilePhoto: "Profile Photo",
  publishChallenge: "Publish Challenge",
  published: "Published",
  question: "Question",
  questions: "Questions",
  received: "Received",
  receivesNotifications: "Receives Notifications",
  recentlyCompleted: "Recently Completed",
  recognition: "Recognition",
  reloadNow: "Reload Now",
  remove: "Remove",
  removedXFromYourWorkspace: "Removed {{name}} from your workspace",
  removedXIdeaFromY: "Removed {{count}} idea from {{name}}",
  removedXIdeaFromY_plural: "Removed {{count}} ideas from {{name}}",
  removeXFromWorkspace: "Deleting {{name}} from your workspace will keep their contributions but will permanently erase all of the connections to them. All contributions will show as anonymous contributions.",
  renameCategory: "Rename Category",
  reOpen: "Re-Open",
  reopeningWillEraseActivitiesFromCollaboration: "Re-opening will erase all activities from the Collaborate stage.",
  reopeningWillEraseActivitiesFromEvaluation: "Re-opening will reset vote counts from Evaluation stage.",
  reopeningWillEraseActivitiesFromCompleted: "Re-opening will undo rewards from Completed stage.",
  reset: "Reset",
  resetPassword: "Reset password",
  review: "Review",
  revokeWorkspaceAdmin: "Revoke workspace admin",
  reward: "Reward",
  role: "Role",
  save: "Save",
  saveChanges: "Save Changes",
  savePhoto: "Save Photo",
  saving: "Saving ...",
  savingChanges: "Saving ...",
  schedule: "Schedule",
  scheduledChallenges: "Scheduled",
  score: "Score",
  search: "Search",
  searchChallenges: "Search Challenges",
  searchContributions: "Search contributions",
  searchForImages: "Search for images",
  searchIdeas: "Search Ideas",
  searchMembers: "Search members",
  second: "second",
  seconds: "seconds",
  seeAllChallenges: "See all challenges",
  seeDetails: "See details",
  select: "Select",
  selectAllIdeas: "Select all ideas",
  selectAllMembers: "Select all members",
  selectCategories: "Select categories",
  selectIdea: "Select this idea",
  selectParticipants: "Select participants",
  sendInvitation: "Send Invitation",
  setDurationForPhrase: "Set the duration for each stage of the challenge.",
  setOptions: "Set options",
  setPassword: "Set Password",
  setRewardsPointsCertificates: "Set your custom rewards.",
  setTimeline: "Set Timeline",
  shareAnonymously: "Share anonymously?",
  shared: "Shared",
  shareIdea: "Share Idea",
  shareRelatedIdea: "Share Related Idea",
  showLess: "Show Less",
  showMore: "Show More",
  showPassword: "Show Password",
  signIn: "Sign In",
  signUp: "Sign Up",
  slides: "Slides",
  someoneIsAskingQuestion: "Someone is asking a question",
  someoneIsDiscussing: "Someone is discussing this idea",
  someoneIsSharingIdea: "Someone is sharing an idea",
  someoneIsSharingRelatedIdea: "Someone is sharing a related idea",
  someoneIsWritingPraise: "Someone is writing a praise",
  somethingWentWrong: "Something went wrong, please try again later.",
  sortBy: "Sort by",
  squareLogo: "Square Logo",
  starIdea: "Star this idea",
  starred: "Starred",
  start: "Start",
  startsWhenCollaborateEnded: "Starts when Collaborate stage has ended.",
  startsWhenIdeateEnded: "Starts when Ideate stage has ended.",
  startsWhenYouSave: "Starts when you save",
  status: "Status",
  strong: "Strong",
  statusDuplicate: "Status Duplicate",
  subdomainIsRequired: "Subdomain is required",
  submit: "Submit",
  submittedByX: "Submitted by {{name}}",
  successfullyRenamedToX: "Successfully renamed to {{name}}",
  successfullyUpdatedX: "Successfully updated {{title}}",
  superAdmin: "Super Admin",
  tagSelectedIdeas: "Tag Selected Idea(s)",
  team: "Team",
  teamMember: "Team Member",
  teamMemberIdeasAreHidden: "Team members’ ideas are hidden until the collaboration stage to encourage creative thinking and avoid group think.",
  thai: "Thai",
  theme: "Theme",
  thereAreNewActivities: "Hey! There are new activities waiting for you to see when you are ready.",
  theWorkspaceInfoIsUpdated: "The workspace info is updated",
  thisStageHasAlreadyEnded: "This stage has already ended",
  week: "Week",
  timeline: "Timeline",
  tip: "Tip",
  titleIsRequired: "Title is required",
  titleOrDescriptionIsRequired: "Either the title or description is required",
  toggleFilters: "Toggle filters",
  toMakeYourPasswordMoreSecure: "To make your password more secure",
  topContributions: "Top Contributions",
  topIdeas: "Top Ideas",
  topParticipation: "Top Participation",
  total: "Total",
  typeNameOrEmail: "Type in a name or email",
  unarchive: "Unarchive",
  unArchiveChallenge: "Unarchive Challenge",
  unArchivingChallenge: "Unarchiving challenge will allow respondents to accessing this page but still not able to submit new idea.",
  unassigned: "Unassigned",
  unassignedIdeas: "Unassigned",
  unauthorizedAccess: "Unauthorized Access",
  unauthorizedToEdit: "Unauthorized to edit",
  underEvaluation: "Under Evaluation",
  unknown: "Unknown",
  unselectIdea: "Un-select this idea",
  unstarIdea: "Un-star this idea",
  upcomingStage: "Upcoming",
  update: "Update",
  upload: "Upload",
  uploadYourLogo: "Upload Your Logo",
  useBothFormatsOfYourLogo: "For a better experience use both formats of your logo; this helps it to look better across different devices such as tablets, and smartphones. If you have only one format, upload it in whichever field fits better.",
  useInventValueLogo: "Use Invent Value Logo",
  useInventValueTheme: "Use Invent Value Theme",
  useUncommonWords: "Use uncommon words",
  useUniquePhrase: "Use unique phrase",
  useYourFullName: "For a better experience use your full name and upload your actual picture of yourself.",
  verifyingCredentials: "Verifying your credentials",
  videos: "Videos",
  viewAllIdeasWithThisTag: "Click to view all Ideas with this tag",
  votes: "Votes",
  votesLeft: "Vote left",
  warning: "Warning",
  weak: "Weak",
  whatsTheBigIdeaRequired: "What’s the big idea? (required)",
  whatsYourChallengeAbout: "What’s your challenge about?",
  willDeleteTheWorkspace: "This will delete the workspace, challenges, ideas, and all other associated ideas.",
  workspaceAdmin: "Workspace Admin",
  workspaceInfo: "Workspace Info",
  workspaceName: "Workspace Name",
  workspaceNameIsRequired: "Workspace name is required",
  workspaces: "Workspaces",
  workspaceTeam: "Workspace Team",
  writeDescriptionHere: "Write description",
  writeYourDiscussionHere: "Write your discussion here",
  writeYourIdeaHere: "Write your idea here",
  writeYourIdeaHereRequired: "Write your idea here (required)",
  writeYourPraiseHere: "Write your praise here",
  writeYourQuestionHere: "Write your question here",
  xContribution: "{{count}} contribution",
  xContribution_plural: "{{count}} contributions",
  xDay: "{{count}} day",
  xDay_plural: "{{count}} days",
  xHasBeenAdded: "{{title}} has been successfully added.",
  xHasBeenCreated: "{{title}} has been successfully created.",
  xHasBeenDeleted: "{{title}} has been successfully deleted.",
  xHasBeenUpdated: "{{title}} has been updated.",
  xHour: "{{count}} hour",
  xHour_plural: "{{count}} hours",
  xIdeaHasBeenLinkedToYOtherIdea: "{{title}} idea has been linked to {{count}} other idea.",
  xIdeaHasBeenLinkedToYOtherIdea_plural: "{{title}} idea has been linked to {{count}} other ideas",
  xIdeaHaveBeenDeleted: "{{count}} ideas have been deleted",
  xIdeaHaveBeenDeleted_plural: "{{count}} ideas have been deleted",
  xIdeaSelected: "{{count}} idea Selected",
  xIdeaSelected_plural: "{{count}} ideas Selected",
  xMemberHaveBeenDeleted: "{{count}} member have been deleted",
  xMemberHaveBeenDeleted_plural: "{{count}} members have been deleted",
  xMemberSelected: "{{count}} member selected",
  xMemberSelected_plural: "{{count}} members selected",
  xMinute: "{{count}} minute",
  xMinute_plural: "{{count}} minutes",
  xMonth: "{{count}} month",
  xMonth_plural: "{{count}} months",
  xSecond: "{{count}} second",
  xSecond_plural: "{{count}} seconds",
  xUsersHasBeenInvitedToJoinWorkspace: "{{count}} user have been invited to join your workspace",
  xUsersHasBeenInvitedToJoinWorkspace_plural: "{{count}} users have been invited to join your workspace",
  xWeek: "{{count}} week",
  xWeek_plural: "{{count}} weeks",
  xYear: "{{count}} year",
  xYear_plural: "{{count}} years",
  year: "Year",
  years: "years",
  youAreNotAuthorizedToDeleteSelectedIdeas: "You are not authorized to delete the following selected ideas",
  youDoNotHaveAccessToThisWorkspace: "You do not have access to this workspace",
  youDoNotHavePermissionToViewThisPage: "You don't have permission to access this page.",
  yourAccountHasBeenDeleted: "Your account has been deleted",
  yourChangelogHasBeenDeleted: "Your changelog has been deleted",
  yourPasswordIsSet: "Your password is set",
  yourPasswordIsUpdated: "Your password is updated",
  yourProfileIsUpdated: "Your profile is updated",
  yourWorkspaceNameWillAppear: "Your workspace name will appear as a workspace on the upper right corner of the application screen and on the browser tab.",
  youWillLoseAccess: "You will lose access to your workspaces and contributions.",
  zeroActivities: "No Activities",
  zeroContributions: "No contributions",
  zeroVotes: "No Votes",
  zeroNotifications: "There are no new notifications",
  sortByNameAlphabetical: "A-Z",
  quickAdd: "Quick Add",
  viewAllChangeLog: "View All Changelog",
  thereAreNoNewNotifications: "There are no new notifications",
  pleaseTypeDeleteToConfirm: "Please type {{keyword}} to confirm",
  workspaceHasBeenDeleted: "Workspace has been deleted",
  suggestions: "Suggestions",
  reviewed: "Reviewed",
  planned: "Planned",
  implemented: "Implemented",
  changeStatus: "Change Status",
  filterBy: "Filter by",
  xUpdateStatusOfIdea: "updated status of this Idea to {{status}}",
  xRemoveStatusOfIdea: "{{status}} is used by {{name}}, Are you sure you want to delete?",
  selectABoard: "Select a board",
  addNewBoard: "Add New Board",
  manageBoard: "Manage Board",
  manageStatus: "Manage Status",
  addStatus: "Add Status",
  updateStatus: "Update Status",
  enterNewBoardNameHere: "Enter the new board name here",
  boardPrivateExplanation: "Only invitees see and participate the board.",
  xHasBeenEdited: "{{title}} has been successfully edited.",
  manageParticipants: "Manage participants",
  deleteBoard: "Delete Board",
  areYouSureToDeleteX: "Are you sure you want to delete {{name}}?",
  createBoard: "Create Board",
  ideaBoard: "Boards",
  createYourFirstBoard: "Create your idea board!",
  createYourFirstBoardExplanation: "Create a private board, add people in your workspace to collaborate with, and start adding ideas! Keep in mind: there is no limit to the number of boards or ideas you can have.",
  createPrivateBoard: "Create a Private Board",
  organizeIdeasEasily: "Organize ideas easily",
  organizeIdeasEasilyExplanation: "Organize ideas easily by creating categories and adding ideas to them.",
  shareYourIdeaNow: "Share your idea now!",
  shareYourIdeaNowExplanation: "Sharing an idea is really easy! You can even add images and videos to make them more visual.",
  newIdeaBoard: "New Board",
  newChallenge: "New Challenge",
  newSuggestion: "New Suggestion",
  newWorkspaceMember: "New Workspace Member",
  share: "Share",
  getLink: "Get Link",
  linkCopied: "Link copied",
  done: "Done",
  onlyBoardMembersCanViewThisIdea: "Only board members can view this idea",
  settings: "Settings",
  allowIdeaBoardManagement: "Allow Board Management",
  allowIdeaBoardManagementExplanation: "Allow non-Admin workspace members to create, manage, and delete private boards.",
  sendReminder: "Send Reminder",
  sendReminderExplanation: "Anyone who has not yet participated in this challenge will receive this reminder message.",
  reminderHasBeenSent: "Reminder email has been sent",
  votesPerParticipant: "Votes per Participant",
  failedToVerifyMention: "Failed to verify your mention, please try again later.",
  inviteParticipant: "Invite this participant?",
  xNeedToBeInvitedToBoardForMention: "Invite {{name}} to this board? You cannot mention someone outside of the private board.",
  xSuggestionsAssignedToStatus: "{{count}} idea have been assigned to the {{status}} status.",
  xSuggestionsAssignedToStatus_plural: "{{count}} ideas have been assigned to the {{status}} status.",
  innovationDirectory: "Innovation Directory",
  assignToStatus: "Assign to Status",
  createThisChallenge: "Create this Challenge",
  viewChallenge: "View Challenge",
  privateChallenge: "Private Challenge",
  more: "More",
  noResultsFound: "No results found.",
  onlyChallengeMemberCanViewThis: "Only challenge member can view this",
  allowOnlyNumberMinToMax: "Allow only number {{min}} to {{max}}",
  getIdeas: "Get Ideas",
  duration: "Duration",
  challengeHasStarted: "Challenge has started",
  startsNow: "Now",
  setEndTime: "Set End Time",
  startsWhenIdeationEndsOnX: "Starts when Ideation ends on {{time}}",
  ideationStarts: "Ideation Starts",
  ends: "Ends",
  categoriesContributorsConnections: "Categories, Contributors & Connections",
  assignCategory: "Assign a Category",
  noAttachment: "No attachment",
  xAttachment: "{{count}} Attachment",
  xAttachment_other: "{{count}} Attachments",
  recommendationEngine: "Recommendation Engine",
  articles: "Articles",
  visuals: "Visuals",
  addAttachments: "Add Attachment(s)",
  download: "Download",
  resources: "Resources",
  addResource: "Add Resource",
  newResource: "New Resource",
  principle: "Principle",
  whitepaper: "White Paper",
  article: "Article",
  link: "Link",
  video: "Video",
  areYouSureYouWantToDeleteResource: "Are you sure you want to delete the resource?",
  deleteResource: "Delete Resource",
  premium: "Premium",
  public: "Public",
  enterBriefDescription: "Enter a brief description",
  title: "Title",
  briefDescription: "Brief Description",
  content: "Content",
  enterVideoUrl: "Enter video URL",
  enterUrl: "Enter URL",
  attachment: "Attachment",
  coverImage: "Cover Image",
  firstName: "First Name",
  lastName: "Last Name",
  company: "Company",
  downloading: "Downloading",
  copyLink: "Copy Link",
  viewAllResources: "View all resources",
  xIsRequired: "{{name}} is required",
  description: "Description",
  requestedPageNotFound: "The requested page could not be found",
  evaluation: "Evaluation",
  autoEvaluate: "Auto Evaluate",
  issueCertificate: "Issue Certificate",
  rewardInfo: "Offer a reward for the best ideas to encourage engagement",
  issueCertificateInfo: "Award winners will be issued a certificate they can download and keep",
  autoEvaluateInfo: "Invent Value will choose challenge winners for you according to contributions shared and received",
  votesReceived: "Votes Received",
  praiseReceived: "Praise Received",
  commentsReceived: "Comments Received",
  ideasShared: "Ideas Shared",
  points: "Points",
  finishEvaluation: "Finish Evaluation",
  totalPointsMustBe100: "Total points must be exactly 100",
  pleaseConfirm: "Please Confirm",
  autoEvaluateWarning: "A winner will be automatically chosen based on votes and praises received and the number of contributions made on this challenge.",
  xResultsFound: "{{count}} results found",
  seeMore: "See More",
  allFieldsAreRequired: "All fields are required",
  deleteX: "Delete {{name}}",
  confirmDeleteX: "Are you sure you want to delete this {{name}}?",
  writeXHere: "Write {{name}} here",
  askChallengeQuestions: "Ask the Moderator",
  askChallengeQuestionsHint: "Have a question about this challenge? Share your questions here and the challenge creator will be notified.",
  message: "Message",
  viewXEarlierY: "View {{count}} earlier {{name}}",
  viewFewerX: "View fewer {{name}}",
  addManyAtOnce: "Add Many at Once",
  enterCommaSeparatedListOfEmails: "Enter a comma separated list of emails",
  messages: "Messages",
  linkedVotes: "Linked Votes",
  voteFailed: "Vote failed",
  showBreakdown: "Show Breakdown",
  allActivities: "All Activities",
  pleaseEnterValidGiphy: "Please enter a keyword or a valid Giphy URL below",
  solutionGrouping: "Solution Grouping",
  moderatorOnly: "Moderator-only",
  moderatorAndAllParticipants: "Moderator + All Participants",
  synthesisStage: "Solution Grouping",
  synthesis: "Solution Grouping",
  synthesisModerationEnds: "Solution Grouping Moderation Ends",
  addSolution: "Add Solution",
  addNew: "Add New",
  groupedIdeas: "Grouped Ideas",
  addIdeasToSolution: "Add ideas to solution",
  whatsTheTitleOfYourSolutionRequired: "What’s the title of your solution? (required)",
  addToSolution: "Add to Solution",
  solution: "Solution",
  deleteSolution: "Delete Solution",
  solutions: "Solutions",
  confirmDeleteChallengeReply: "Are you sure you want to delete this comment?",
  masonryView: "Masonry View",
  solutionRequirements: "Solution requirements",
  describeSolutionRequirements: "List any specific requirements that solutions must follow (optional)",
  enterSolutionRequirements: "Enter your requirements",
  showRequirements: "Show requirements",
  challengeDescription: "Challenge Description",
  convertIdeaToSolution: "Convert idea to Solution",
  selectToAddToSolutions: "Select to add to solution(s):",
  yourChallengeModeratorIsSynthesizing: "Your challenge moderator is still grouping solutions. We’ll email you once it’s time to vote.",
  pleaseAddSolutionsToMoveToNextPhase: "Please add solutions to be able to move to the next stage.",
  pleaseConfirmSolutions: "Please confirm once you have finished reviewing solutions",
  submitNumSolutions: "Finalize the {{count}} solution",
  submitNumSolutions_plural: "Finalize the {{count}} solutions",
  youHaveSubmittedNumSolutions: "You have submitted {{count}} solution for evaluation",
  youHaveSubmittedNumSolutions_plural: "You have submitted {{count}} solutions for evaluation",
  solutionsWillBeAutomaticallyReleased: "The solutions you submitted will be automatically released to the Evaluation stage at the end of the Solution Grouping stage or you can start the Evaluation stage immediately. ",
  editChoices: "Edit choices",
  startEvaluationStage: "Start Evaluation Stage",
  numRelatedIdeas: "{{count}} Related Idea",
  numRelatedIdeas_plural: "{{count}} Related Ideas",
  showAll: "Show All",
  searchSolutions: "Search Solutions",
  view: "View",
  addToX: "Add to {{name}}",
  integration: "Integration",
  xIntegration: "{{name}} Integration",
  backToHome: "Back to Home",
  openX: "Open {{name}}",
  exportChallenge: "Export challenge",
  ideaBoards: "Idea Boards",
  export: "Export",
  selectAFileType: "Select a file type",
  thisChallengeWillBeExportedAsCSV: "This challenge will be exported as a CSV file using a predefined template",
  options: "Options",
  convertTagsToCategories: "Convert tags to categories",
  carryOverComments: "Carry over comments",
  carryOverVotes: "Carry over votes",
  carryOverParticipants: "Carry over participants",
  backToX: "Back to {{name}}",
  reminder: "Reminder",
  sendIndividualReminder: "Send individual reminder",
  customizeTheReminderMessage: "Customize the default reminder message below, or send it as is:",
  sendReminderToX: "Send Reminder to {{name}}",
  everyone: "Everyone",
  send: "Send",
  remindEveryone: "Remind Everyone",
  thisChallengeWillBeExportedAsGoogleSheets: "This challenge will be exported to Google Sheets using a predefined template",
  thisChallengeWillBeExportedAsGoogleSlides: "This challenge will be exported to Google Slides using a predefined template",
  collapse: "Collapse",
  expand: "Expand",
  noQualifyingTopX: "No qualifying top {{name}}",
  newBoard: "New Board",
  topSolutions: "Top Solutions",
  addX: "Add {{name}}",
  moveToWorkspace: "Move to Workspace",
  sendRequestToMoveChallenge: "Send request to move a challenge to another workspace",
  sendMoveRequestExplanation: "Once you submit this request, please give the Invent Value help team at least 72 hours to complete this request. Thanks!",
  selectX: "Select {{name}}",
  workspace: "Workspace",
  sendRequestViaEmail: "Send Request via Email",
  ungroupedIdeas: "Ungrouped Ideas",
  allIdeasNotInSolution: "All ideas that have not yet been moved into a solution",
  unknownErrorOccurred: "An unknown error has occurred",
  contactSupport: "Contact Support",
  continueEditing: "Continue Editing",
  finalizeChallenge: "Finalize Challenge",
  allowSuggestionFeatures: "Allow Suggestions Features",
  allowSuggestionFeaturesExplanation: "Allow Suggestions Features to show up in the menu and be accessible to all of the users of this workspace.",
  ideasContributedBy: "Ideas contributed by:",
  xIdeas: "{{count}} Idea",
  xIdeas_plural: "{{count}} Ideas",
  xSolutions: "{{count}} Solution",
  xSolutions_plural: "{{count}} Solutions",
  allowIdeaBoardFeatures: "Allow Board Features",
  allowIdeaBoardFeaturesExplanation: "Allow Board Features to show up in the menu and be accessible to all of the users of this workspace.",
  workspaceInvitation: "Workspace Invitation",
  invitations: "Invitations",
  type: "Type",
  rule: "Rule",
  emailDomain: "Email Domain",
  latestUpdated: "Last Updated",
  creator: "Creator",
  xInvitationsSelected: "{{count}} invitation selected",
  xInvitationsSelected_plural: "{{count}} invitations selected",
  createInvitation: "Create Invitation",
  deactivated: "Deactivated",
  limitedTime: "Limited Time",
  expiresOn: "Expires on",
  validFor: "Valid for",
  deactivate: "Deactivate",
  areYouSureToDeactivateXInvitations: "Are you sure you want to deactivate the invitation?",
  areYouSureToDeactivateXInvitations_plural: "Are you sure you want to deactivate {{count}} invitations?",
  deactivatedXInvitations: "Deactivated {{count}} invitation",
  deactivatedXInvitations_plural: "Deactivated {{count}} invitations",
  deactivateInvitation: "Deactivate invitation",
  deactivateInvitation_plural: "Deactivate invitations",
  selectAllInvitations: "Select all invitations",
  xInvitationsLeft: "{{count}} invitation left",
  xInvitationsLeft_plural: "{{count}} invitations left",
  createWorkspaceInvitation: "Create Workspace Invitation",
  limitedByEmailDomain: "Limited by Email Domain",
  anyoneWithThisEmailDomainCanUseTheInvitationLink: "Anyone with this email domain can use the invitation link",
  limitedNumberOfUses: "Limited Number of Uses",
  theInvitationLinkExpiresAfterSpecifiedUse: "The invitation link expires after specified number of uses",
  limitedTimeUse: "Limited-Time Use",
  theInvitationLinkExpiresAfterSpecifiedTime: "The invitation link expires after specified time",
  limitedUse: "Limited Use",
  xUsesLeft: "{{count}} uses left",
  editWorkspaceInvitation: "Edit Workspace Invitation",
  validEmailDomainIsRequired: "A valid email domain is required",
  now: "Now",
  exportSolutions: "Export Solutions",
  exportIdeas: "Export Ideas",
  canvasView: "Canvas View",
  addNewMentalModel: "Add New Mental Model",
  addANewMentalModelHere: "Add a new mental model here!",
  addANewMentalModelHereExplanation: "Leverage one of the many mental models available to you to better organize and analyze your ideas.",
  addIdeasToTheCanvas: "Add ideas to the canvas!",
  addIdeasToTheCanvasExplanation: "All of the ideas in this board can be filtered and searched. Use the “Add to Canvas” button to add your ideas!",
  addToCanvas: "Add to Canvas",
  findAMentalModel: "Find a Mental Model",
  planAndStrategize: "Plan and Strategize",
  understand: "Understand",
  getCreative: "Get Creative",
  decide: "Decide",
  startFromBlank: "Start from Blank",
  rename: "Rename",
  copy: "Copy",
  renameCanvas: "Rename Canvas",
  enterNewCanvasNameHere: "Enter new canvas name here",
  deleteCanvas: "Delete Canvas",
  confirmDeleteIdeaCanvas: "Are you sure you want to delete this canvas?",
  pleaseCreateCanvasFirst: "Please create a canvas first",
  action: "Action",
  amount: "Amount",
  amazonGiftCard: "Amazon eGift Card",
  appleGiftCard: "Apple eGift Card",
  bestbuyGiftCard: "Best Buy eGift Card",
  chooseTypeAndAmountOfRewards: "Choose the Amount (under ${{rewardValueLimit}}) and type of reward that the challenge winner will receive.",
  rewardAmountExceedsLimit: "The amount of reward must be under ${{rewardValueLimit}}",
  copied: "Copied",
  customReward: "Custom Reward",
  enterAmount: "Enter Amount",
  finish: "Finish",
  rewards: "Rewards",
  sendRewards: "Send Rewards",
  sent: "Sent",
  rewardsHasBeenSent: "Rewards has been sent",
  clickToCopy: "Click to Copy",
  ok: "OK",
  readTheMessages: "Read the messages",
  thereAreNewMessages: "There are new messages.",
  customGiftCardImage: "Custom Gift Card Image",
  publishGoogleSlidesToWeb: "Make Google Slides presentation publicly available",
  publishGoogleSlidesToWebExplanation: "This will allow others to see the presentation you’ve exported",
  play: "Play",
  exportBoard: "Export Board",
  thisBoardWillBeExportedAsGoogleSlides: "This board will be exported to Google Slides using a predefined template",
  makeBoardPubliclyAccessible: "Make board publicly accessible",
  selectExportingOption: "Select exporting option",
  thisBoardWillBeExportedAsCSV: "This board will be exported as a CSV file using a predefined template",
  thisBoardWillBeExportedAsGoogleSheets: "This board will be exported to Google Sheets using a predefined template",
  canvasName: "Canvas Name",
  addTeam: "Add Team",
  addTeamMember: "Add Team Member",
  editTeam: "Edit Team",
  renameTeam: "Rename Team",
  deleteTeam: "Delete Team",
  removeFromTeam: "Remove From Team",
  enterNewTeamNameHere: "Enter the new team name here",
  organizeTeamEasily: "Organize team easily",
  organizeTeamEasilyExplanation: "Organize team easily by creating groups and adding members to them.",
  deleteXTeam: 'Delete "{{name}}" team',
  membersWillBeUnassigned: "All the members in this team will still exist, but will be unassigned.",
  addMembersToTeamX: "Add Members To Team {{groupName}}",
  xMemberHaveBeenAddedToTeamX: "{{count}} member have been added to {{groupName}}",
  xMemberHaveBeenAddedToTeamX_plural: "{{count}} members have been added to {{groupName}}",
  archivingMember: "Deactivating members will prevent them from accessing this workspace.",
  unarchivingMember: "Activating members will allow them to access this workspace.",
  memberIsArchived: "{{memberName}} is deactivated",
  memberIsUnarchived: "{{memberName}} is activated",
  removeInvitation: "Remove Invitation",
  invitationIsRemoved: "The invitation is removed",
  youAreGuest: "You are guest",
  welcomeToWorkspace: "Welcome, you are now part of the {{workspaceName}}",
  contactToRejoinWorkspace: "Please contact the person who invited again to re-join the {{workspaceName}}",
  accept: "Accept",
  ignore: "Ignore",
  xMemberHaveBeenArchived: "{{count}} member have been deactivated",
  xMemberHaveBeenArchived_plural: "{{count}} members have been deactivated",
  xMemberHaveBeenUnarchived: "{{count}} member have been activated",
  xMemberHaveBeenUnarchived_plural: "{{count}} members have been activated",
  noMemberToArchive: "No members to deactivate",
  noMemberToUnarchive: "No members to activate",
  noUsersToInvite: "No users to invite",
  shareBoard: "Share Board",
  anyoneOnTheInternetCanViewThisIdea: "Anyone on the internet can view this idea",
  onlyBoardMembersCanViewThisBoard: "Only board members can view this board",
  shareThisBoardWithEveryone: "Anyone on the internet can view this board if they have this link.",
  clickToViewThePublicBoard: "Click to view the public board",
  viewPresentation: "View Presentation",
  pleaseSelectBoard: "Please select Board",
  newIdea: "New idea",
  seeTheIdea: "See the idea",
  addToChrome: "Add to Chrome",
  weSeeYouAreUsingChrome: "We see you’re using Chrome!",
  installChromeExtension: "Install the “Easy Capture” Chrome Extension",
  installChromeExtensionDescription: "Save images from around the web with one click! Also, use the built-in screen capture tool to capture your selected area, visible area, or full page.",
  saveToIdeaBoard: "Save to an Idea Board",
  saveToBoard: "Save to Board",
  board: "Board",
  step: "Step",
  imageSaving: "Image Saving",
  screenCapture: "Screen Capture",
  setupFinished: "Setup Finished",
  thanksForAddingEasyCapture: "Thanks for adding the Invent Value Easy Capture Chrome extension. To get the most out of this tool, staying signed-in is strongly recommended!",
  useButtonBelowToSignIn: "Use the button below to sign in through Invent Value.",
  signInThroughInventValue: "Sign in through Invent Value",
  saveImagesFromAroundTheWebWithOneClick: "Save images from around the web with one click!",
  hoverOverAnImageYouWantToSave: "Hover over an image you want to save.",
  pickTheBoardToSaveIt: "Pick the board to save it to.",
  screenCaptureGuide: "You can click the extension icon on the rights side of the address bar to start using the built-in screen capture tool to capture the selected area, visible area, or full page!",
  youAreAllSet: "You’re all set!",
  dontHesitateToReachOut: "Don’t hesitate to reach out if you have any issues, feedback, or questions.",
  allSet: "All Set!",
  startOver: "Start Over",
  clickThe: "Click the",
  icon: "icon.",
  imageSource: "Image Source",
  deactivatingWorkspaceMemberWill: "Deactivating a workspace member will prevent them from accessing this workspace and will prevent them from being listed on this page.",
  thisIsNotReversible: "This is not reversible.",
  typeDeleteToConfirm: "Type DELETE to confirm",
  activate: "Activate",
  selectView: "Select View",
  selectCanvas: "Select Canvas",
  canvas: "Canvas",
  grid: "Grid",
  list: "List",
  boardUrl: "Board URL",
  selectDefaultView: "Select default view",
  someoneAddedIdea: "Someone added an idea",
  someoneAddedQuestion: "Someone added a question",
  selectViewToShare: "Select view to share",
  moveAllIdeasToCanvas: "Move all Ideas to Canvas",
  move: "Move",
  xIdeasAlreadyExistsInTheCanvas: "{{count}} idea already exist in the canvas. This action will replace them and add all ideas.",
  xIdeasAlreadyExistsInTheCanvas_plural: "{{count}} ideas already exist in the canvas. This action will replace them and add all ideas.",
  allIdeasWillBeMovedToTheCanvas: "All ideas will be moved to the canvas.",
  replace: "Replace",
  moveAllIdeas: "Move All Ideas",
  filterByCategories: "Categories",
  recentlyViewed: "Recently Viewed",
  personalBoards: "Personal Boards",
  sharedBoards: "Shared Boards",
  publicBoards: "Public Boards",
  enterNewBoardDescriptionHere: "Enter the board description here",
  searchX: "Search {{name}}",
  backToBoards: "Back to Boards",
  viewDescription: "View Description",
  boards: "Boards",
  challengeModeratorsTitle: "Challenge moderator(s)",
  challengeModeratorsDescription: "Challenge moderator(s) will have the same permissions as a challenge creator. They can help draft challenges as well as moderate a live challenge.",
  youAreEditing: "You are editing",
  xIsEditing: "{{name}} is editing",
  exportCanvas: "Export Canvas",
  noDescription: "No description provided",
  xPeopleAreEditingTheChallenge: "{{count}} people are editing the challenge",
  refreshPageRequired: "Hey! Refreshing the page will be required to see other users’ updates.",
  shuffleSuccess: "Ideas are shuffled into a new order, scroll down to see them.",
  thisIdeaIsHiddenDuringIdeation: "Other people's ideas are hidden during Ideation stage.",
  sharedBoardsWillAppearHere: "Shared boards will appear here",
  publicBoardsWillAppearHere: "Public boards will appear here",
  recentlyViewedBoardsWillAppearHere: "Recently viewed boards will appear here",
  createPersonalBoard: "Create Personal Board",
  createSharedBoard: "Create Shared Board",
  createPublicBoard: "Create Public Board",
  inProgress: "In progress",
  moveUp: "Move Up",
  moveDown: "Move Down",
  fewestContributions: "Fewest Contributions",
  workspaceInvitationSetting: "Invitation to a new workspace",
  workspaceInvitationSettingExplanation: "Allows a user to accept an invitation. Strongly recommended to be left on.",
  emailVerificationSetting: "Email Verification",
  emailVerificationSettingExplanation: "Allows a user to verify their email address. Strongly recommended to be left on.",
  welcomeNotificationSetting: "Welcome to Invent Value",
  welcomeNotificationSettingExplanation: "A welcome email after being added to Invent Value for the first time. Great for new users.",
  weeklyReportNotificationSetting: "Weekly Innovation Report",
  weeklyReportNotificationSettingExplanation: "A weekly workspace innovation report summarizing participation & challenges for the past week.",
  challengeInvitationNotificationSetting: "Invitation to participate in a challenge",
  challengeInvitationNotificationSettingExplanation: "This is the entry point for participants to enter into a challenge after being added directly to a private or team challenge.",
  challengeStartNotificationSetting: "Start of each stage",
  challengeStartNotificationSettingExplanation: "A way to inform participants that a challenge has moved from one stage to the next. Strongly recommended to be left on.",
  challengeMentionNotificationSetting: "Direct mentions in a challenge",
  challengeMentionNotificationSettingExplanation: "Informs those directly mentioned (E.g. @johnnyrodriguez) in a challenge that they’re being talked to or referenced.",
  challengeMessageNotificationSetting: "Questions to the moderator",
  challengeMessageNotificationSettingExplanation: "Informs the challenge creator and moderator, if applicable, that there is a question that needs to be answered. Strongly recommended to be left on.",
  challengeParticipantReminderNotificationSetting: "Reminder to participate in a challenge",
  challengeParticipantReminderNotificationSettingExplanation: "A challenge creator or moderator can send a user a reminder to participant using a button at the top of a challenge. A great way to remind users.",
  challengeReopenNotificationSetting: "Re-opening of a stage",
  challengeReopenNotificationSettingExplanation: "If a stage is moved back or forward manually by the challenge creator or moderator, this informs the participants involved in the challenge.",
  challengeTimeLeftNotificationSetting: "Time left stage reminders",
  challengeTimeLeftNotificationSettingExplanation: "Informs users that they can participant in the relevant stage for 24 more hours and 1 hour.",
  challengeSummaryNotificationSetting: "End of challenge review",
  challengeSummaryNotificationSettingExplanation: "This is a challenge summary email sent to participants to let them know the results of a challenge. Includes stats, winning ideas, etc.",
  challengeReplyNotificationSetting: "People are talking about your idea",
  challengeReplyNotificationSettingExplanation: "Informs an idea submitter that they have received a response to their idea. This could be a praise, question, additional idea, or further discussion.",
  challengeRewardNotificationSetting: "Giftcard is on the way (for recipient)",
  challengeRewardNotificationSettingExplanation: "Informs the winner of a challenge that the details regarding the gift card they are receiving will be arriving via email soon.",
  challengeRewardReminderNotificationSetting: "Giftcard details (for creator or moderator)",
  challengeRewardReminderNotificationSettingExplanation: "Informs the creator or moderator the details about how to send the gift card to the challenge winner.",
  challengeCongratulationNotificationSetting: "Congratulations to the winner of the challenge",
  challengeCongratulationNotificationSettingExplanation: "Informs the winner of a challenge that they’ve won the challenge (received the most votes and selected by the creator as the winner).",
  challengeAutoEvaluatedNotificationSetting: "Challenge has been auto evaluated",
  challengeAutoEvaluatedNotificationSettingExplanation: "Informs the challenge creator and moderator that their challenge was auto evaluated.",
  boardReplyNotificationSetting: "Comment on a submitted idea",
  boardReplyNotificationSettingExplanation: "Informs the idea submitter that there is a comment on the idea they submitted.",
  boardMemberAddedNotificationSetting: "Getting added as a contributor",
  boardMemberAddedNotificationSettingExplanation: "Informs the recently added user that they’ve been added to a board as a contributor.",
  suggestionReplyNotificationSetting: "Comment on a submitted suggestions",
  suggestionReplyNotificationSettingExplanation: "Informs the suggestion submitter that there is a comment on the suggestions they submitted.",
  myNotifications: "My Notifications",
  allWorkspaces: "All Workspaces",
  challengeNotifications: "Challenge Notifications",
  challengeNotificationsExplanation: "Define the notifications a user should receive for this specific challenge.",
  workspaceNotificationSettingDescription: "These settings can be overridden by a user, or a challenge",
  accountNotificationSettingDescription: "These settings can be overridden by a challenge",
  challengeNotificationSettingDescription: "These settings override the user and admin settings",
  workspaceInvitationOffWarning: "Invitation notification is currently OFF; the invitee will not receive the invitation email.",
  promptToTurnItOn: "Do you want to turn it on?",
  contactAdminToTurnNotificationOn: "You can contact your workspace admin to turn the notification ON.",
  yesTurnItOn: "Yes, turn it on",
  notificationIsEnabled: "Notification is enabled",
  emailVerificationOffWarning: "Email Verification is currently OFF;",
  canLoginWithoutEmailVerification: "can login without verifying their email address.",
  turnOnAndSendInvitation: "Turn It On And Send Invitation",
  sendInvitationOnly: "Send Invitation Only",
  startFollowing: "Start Following",
  stopFollowing: "Stop Following",
  startedFollowingTheChallenge: "Started following the challenge",
  stoppedFollowingTheChallenge: "Stopped following the challenge",
  or: "or",
  mergeAccounts: "Merge Accounts",
  mergeAccountTitle: "Enter the email address and password of the secondary account",
  mergeAccountExplanation: "To verify your another existing account. All workspaces and contributions from the secondary account will be transferred to the primary account. The secondary account will be closed.",
  verifyTheAccount: "Verify The Account",
  reviewMergeAccountTitle: "Review the account you want to merge and close",
  willBePermanentlyClosed: "will be permanently closed.",
  confirmToMergeAndClose: "Confirm to Merge and Close",
  mergeRequestSentSuccessfully: "Merging request sent successfully",
  mergeRequestSentExplanation: "You'll receive an email confirming once the process is completed.",
  mergeAnotherAccount: "Got it, merge another account",
  selectPrimaryAccount: "Select primary account",
  enterNameOrEmailHere: "Enter name or email here",
  challenge_plural: "Challenges",
  board_plural: "Boards",
  idea_plural: "Ideas",
  workspace_plural: "Workspaces",
  canvas_plural: "Canvases",
  suggestion: "Suggestion",
  suggestion_plural: "Suggestions",
  contribution: "Contribution",
  contribution_plural: "Contributions",
  result: "Result",
  result_plural: "Results",
  changePrimaryAccount: "change primary account",
  typeMergeToConfirm: "Type MERGE to confirm",
  merging: "Merging",
  howCanWeHelp: "How can we help?",
  describeTheProblemYouAreHaving: "Describe the problem you're having",
  weWillGetBackToYou: "Thanks, we will get back to you soon!",
  poweredBy: "Powered by",
  emailAddress: "Email Address",
  placeholderPeopleName: "E.g. John Doe",
  placeholderEmail: "E.g. john.doe@inventvalue.com",
  attachmentOptional: "Attachment (optional)",
  surveyThank: "Thank you for completing your submission!",
  openInventValue: "Open Invent Value",
  publish: "Publish",
  widget: "Widget",
  form: "Form",
  addQuestion: "Add a question",
  preventSubmissionAfter: "Prevent submission after",
  never: "Never",
  expiresAtCertainTime: "Specify date",
  reportProblem: "Report a Problem",
  pleaseAddQuestion: "Please add a question",
  questionDescriptionIsRequired: "Question description is required",
  shareWidget: "Share Widget",
  shareForm: "Share Form",
  deleteWidget: "Delete Widget",
  deleteForm: "Delete Form",
  confirmDeleteSurveyForm: "Are you sure you want to delete the form? Submitted answers won’t be affected.",
  confirmDeleteSurveyWidget: "Are you sure you want to delete the widget? Submitted answers won’t be affected.",
  codeCopied: "Code copied",
  pleaseAnswerThisQuestion: "Please answer this question",
  shareAnIdea: "Share an Idea",
  embedHTML: "Embed HTML",
  height: "Height",
  width: "Width",
  solutionGroupingInfo: "Turning on Solution Grouping allows participants to consolidate multiple ideas into the same group during the collaboration stage.",
  everyoneInTheWorkspace: "Everyone in the workspace",
  weWillEmailYou: "We will email you",
  thisTabIsClosingInSeconds: "This tab is closing in {{second}} seconds",
  workstreams: "Workstreams",
  createThisX: "Create this {{name}}",
  addThisMentalModel: "Add this mental model",
  mentalModel: "Mental Model",
  learnMore: "Learn More",
  selectIdeaBoard: "Select Idea Board",
  xParticipants: "{{count}} Participant",
  xParticipants_plural: "{{count}} Participants",
  VR: "VR",
  seeAllChangelog: "See all changelog",
  admin: "Admin",
  notificationSettings: "Notification Settings",
  youAreInvitedToParticipateInAChallenge: "You are invited to participate in a challenge",
  needsYourCreativeInputFor: "needs your creative input for",
  The: "The",
  stageStartedOnTheChallenge: "stage started on the challenge",
  hasJoined: "has joined",
  workspaceAs: "workspace as",
  a: "a",
  an: "an",
  allNotifications: "All Notifications",
  addWidget: "Add a Widget",
  addForm: "Add a Form",
  feedback: "Feedback",
  createWidget: "Create Widget",
  createForm: "Create Form",
  expired: "Expired",
  viewBoard: "View Board",
  assoicateToBoard: "Associate to Board",
  expirationTime: "Expiration Time",
  enabledMediaAttachments: "Enabled media attachments",
  archiveSurvey: "Archive Survey",
  unarchiveSurvey: "Unarchive Survey",
  archivingSurvey: "Archiving survey will prevent respondents from submitting new answer",
  unarchivingSurvey: "Unarchiving survey will allow respondents to submit new answer",
  surveyHasBeenArchived: "survey has been archived",
  surveyHasBeenUnarchived: "survey has been unarchived",
  theFormIsNoLongerAcceptngResponses: "The form is no longer accepting responses",
  youCanGoTo: "You can go to",
  toEditAndShareCreatedNameLater: "to edit and share the created {{name}} later.",
  dropToAddAnIdea: "Drop to add an idea",
  makeThisBoardAvailableToTheEntireTeam: "Make this board available to the entire team",
  feedbackSubTitle: "Capture the voice of the customer/team using easy forms and widgets",
  searchByTitle: "Search by title...",
  formAndWidgetTitle: "Form/Widget Title",
  associatedBoard: "Associated Board",
  submissions: "Submissions",
  autoCreateBoard: "Create board automatically",
  connectFormSubmissionsToABoard: "Connect {{type}} Submissions To a Board",
  categoryOptions: "Category Options",
  addMoreCategoryOptions: "Add More Category Options",
  enterCategoryNameHere: "Enter category name here...",
  reportABug: "Report a Bug",
  suggestAFeature: "Suggest a Feature",
  shareFeedback: "Share Feedback",
  pleaseEnterATitleForYourSubmission: "Please enter a title for your submission...",
  change: "Change",
  enterYourDescription: "Enter your descritpion...",
  selectCategory: "Select Category",
  addATitleToYourField: "Add a title to your {{field}}",
  addADescriptionToYourField: "Add a description to your {{field}}",
  useDefaultIcon: "Use Default Icon",
  youAreNotAbleToUploadImagesInThePreview: "You are not able to upload images in the preview",
  categoryOptionsMustBeUnique: "Category options must be unique",
  categoryOptionsMustNotBeEmpty: "Category options must not be empty",
  presentationView: "Presentation View",
  embeddedGoogleSlidesPresentation: "Embedded Google Slides Presentation",
  clickOnThePresentation: "click on the presentation",
  toToggleFullScreen: "To toggle full screen",
  forMac: "for Mac",
  forWindows: "for Windows",
  clickToExportToGoogleSlides: "Click to export to Google Slides",
  presentation: "Presentation",
  and: "and",
  justNow: "Just now",
  exported: "Exported",
  by: "by",
  editPresentation: "Edit Presentation",
  fullScreen: "Full Screen",
  weAreGeneratingYourPresentation: "We are generating your presentation, this may take up to 10 seconds.",
  presentationIsUpdated: "The presentation is updated",
  arrow: "Arrow",
  text: "Text",
  shape: "Shape",
  line: "Line",
  rectangle: "Rectangle",
  roundedRectangle: "Rounded Rectangle",
  ellipse: "Ellipse",
  pencil: "Pencil (Freehand Drawing)",
  connectionLine: "Connection Line",
  crop: "Crop",
  undo: "Undo",
  borderColor: "Border Color",
  color: "Color",
  size: "Size",
  deleteImage: "Delete Image",
  editImage: "Edit Image",
  imageAnnotations: "Image Annotations",
  rounded_rectangle: "Rounded Rectangle",
  red: "Red",
  smallest: "Smallest",
  small: "Small",
  large: "Large",
  largest: "Largest",
  annotate: "Annotate",
  createGoogleSlides: "Create Google Slides",
  newChallenges: "New Challenges",
  manageMentalModels: "Manage Mental Models",
  mentalModels: "Mental Models",
  addACustomMentalModel: "Add a custom mental model",
  selectACategory: "Select a category",
  selectACanvasType: "Select a canvas type",
  selectAWorkspace: "Select a workspace",
  rowX: "Row {{number}}",
  gridX: "Grid {{number}}",
  circleX: "Circle {{number}}",
  saveToAllWorkspaces: "Save to all workspaces",
  saveToASpecificWorkspace: "Save to a specific workspace",
  kanban: "Kanban",
  venn: "Venn Diagram",
  pinpoint: "Pinpoint",
  order: "Order",
  selectCircles: "Select Circles",
  selectRows: "Select rows",
  xyAxis: "X-Y Axis",
  xLabel: "X Label",
  enterXLabel: "Enter X Label",
  yLabel: "Y Label",
  enterYLabel: "Enter Y Label",
  chooseAWorkspace: "Choose a workspace",
  none: "None",
  enterName: "Enter name",
  customMentalModelTitle: "Add a Custom Mental Model to your Workspace",
  selectCanvasType: "Select a canvas type",
  selectColumns: "Select Columns",
  enterColumns: "Enter Columns",
  nameLabels: "Name Labels",
  nameLabel: "Name Label",
  columnsX: "Columns {{number}}",
  enterColumn: "Enter Column",
  valueMustBetween: "The value must be between {{min}} to {{max}}.",
  fieldsAreRequired: "These fields are required.",
  selectGrid: "Select Grid",
  column: "Column",
  row: "Row",
  enterRow: "Enter Row",
  enterRows: "Enter Rows",
  labelsPosition: "Labels Position",
  grid2x2: "Grid 2x2",
  enterGrid: "Enter Grid",
  corner: "Corner",
  outside: "Outside",
  top: "Top",
  xyGraph: "X-Y Graph",
  enterCircle: "Enter Circle",
  enterPinpoint: "Enter Pinpoint",
  canvasType: "Canvas Type",
  deleteMentalModel: "Delete Mental Model",
  confirmDeleteMentalModel: "Are you sure you want to delete this mental model?",
  confirmDeleteMentalModelCategory: "Are you sure you want to delete this mental model category?",
  addMentalModel: "Add Mental Model",
  areYouSureToDeleteXModels_one: "Are you sure you want to delete the mental model?",
  areYouSureToDeleteXModels_other: "Are you sure you want to delete {{count}} mental models?",
  pleaseSelectMentalModelToDelete: "Please select mental models to delete.",
  deleteModel: "Delete Model",
  categoryX: "Category ({{value}})",
  canvasTypeX: "Canvas Type ({{value}})",
  workspaceX: "Workspace ({{value}})",
  searchMentalModels: "Search Mental Models",
  xMentalModelsHaveBeenDeleted: "{{value}} mental models have been deleted",
  thisMentalModelWillbeSavedToAllWorkspaces: "This mental model will be saved to all workspaces.",
  thisMentalModelWillbeSavedToSpecificWorkspace: "This mental model will be saved to a specified workspace.",
  editMentalModelCategory: "Edit Mental Model Category",
  uncategorized: "Uncategorized",
  apply: "Apply",
  stopCropping: "Stop Cropping",
  transparent: "Transparent",
  attachmentImageOptional: "Attach image (optional)",
  attachmentVideoOptional: "Attach video (optional)",
  attachedImage: "Attached image",
  attachedVideo: "Attached video",
  browserVideoNotSupport: "Your browser does not support the video tag.",
  videoAttached: "Video attached",
  addCustomMentalModal: "+ Custom Mental Model",
  createCustomMentalModal: "Create Custom Mental Model",
  totalchallenges: "Total Challenges",
  thisMonth: "This Month",
  thisQuarter: "This Quarter",
  thisYear: "This Year",
  seededChallenges: "Seeded Challenges",
  seededBoards: "Seeded Boards",
  addChallenges: "Add Challenges",
  selectChallengeHere: "Select challenge here",
  selectWorkspace: "Select Workspace",
  selectWorkspaceHere: "Select workspace here",
  addBoards: "Add Boards",
  selectBoardHere: "Select board here",
  selectAll: "Select All",
  seededChallengesIsUpdated: "Seeded challenges is updated.",
  addExampleBoardsX_one: "Add Example Board ({{count}})",
  addExampleBoardsX_other: "Add Example Boards ({{count}})",
  addExampleChallengesX_one: "Add Example Challenge ({{count}})",
  addExampleChallengesX_other: "Add Example Challenge ({{count}})",
  draftChallenges: "Draft Challenges",
  enterChallengeTitleHere: "Enter challenge title here",
  draftMoreChallenges: "Draft More Challenges",
  evaluationMethods: "Evaluation Methods",
  selectUpTo3EvaluationMethods: "Select up to 3 evaluation methods",
  selectThisEvaluationMethod: "Select this evaluation method",
  deselectThisEvaluationMethod: "Deselect this evaluation method",
  vote: "Vote",
  vote_other: "Votes",
  evaluation_method_vote: "Vote",
  evaluation_method_ranking: "Ranking",
  evaluation_method_matrix: "Matrix",
  evaluation_method_rate: "Rate",
  evaluation_method_venn: "Venn",
  ranking_order: "Order Ranking",
  ranking_quality: "Quality Ranking",
  ranking_when: "Now, Soon, Later",
  ranking_cost_efficiency: "Cost Efficiency",
  ranking_importance: "Importance",
  ranking_roadmap: "Roadmap",
  ranking_innovation_matrix: "Innovation Matrix",
  matrix_effort_vs_impact: "Effort vs. Impact",
  matrix_value_vs_time: "Value vs. Time",
  matrix_difficult_vs_risk: "Difficult vs. Risk",
  matrix_effort_vs_reward: "Effort vs. Reward",
  matrix_need_vs_want: "Need vs. Want",
  venn_sector_reliance: "Sector Reliance",
  venn_lenses_of_innovation: "Lenses Of Innovation",
  rate_consumer_industrial: "Consumer / Industrial",
  rate_likert: "Likert",
  rate_abstract_literal: "Abstract / Literal",
  rate_fragmented_completed: "Fragmented / Completed",
  rate_idea_development_stage: "Idea Development Stage",
  effort: "Effort",
  impact: "Impact",
  need: "Need",
  want: "Want",
  value: "Value",
  time: "Time",
  difficult: "Difficult",
  risk: "Risk",
  disruptive: "Disruptive",
  architectural: "architectural",
  incremental: "incremental",
  radical: "radical",
  q1: "Q1",
  q2: "Q2",
  q3: "Q3",
  q4: "Q4",
  good: "Good",
  better: "Better",
  best: "Best",
  high: "High",
  low: "Low",
  soon: "Soon",
  later: "Later",
  first: "First",
  third: "Third",
  technology: "Technology",
  business: "Business",
  desirability: "Desirability",
  feasibility: "Feasibility",
  viability: "Viability",
  consumer: "Consumer",
  industrial: "Industrial",
  disagree: "Disagree",
  agree: "Agree",
  fragmented: "Fragmented",
  complete: "Complete",
  developing: "Developing",
  wellformed: "Wellformed",
  firstMethod: "1st Method",
  secondMethod: "2nd Method",
  thirdMethod: "3rd Method",
  dropHere: "Drop here",
  evaluation_method_info_vote: "Weighted voting can be used to help good ideas float to the top. Users can use 1 or more votes an a single idea.",
  evaluation_method_info_ranking_order: "Map out your ideas based on how important your assumptions are compared to how easy it is to test. This will help you pin point the which ideas hold the most promise.",
  evaluation_method_info_ranking_quality: "This allows you to order your top tops ideas.",
  evaluation_method_info_ranking_when: "Decide whether you believe the idea should be executed now, soon, or later.",
  evaluation_method_info_ranking_cost_efficiency: "Judge your ideas based on a single simple dimension. This straightforward framework allows you to focus on what ideas are the most cost efficient.",
  evaluation_method_info_ranking_importance: "Judge your ideas based on a single simple dimension. This straightforward framework allows you to focus on what ideas are the most important to you.",
  evaluation_method_info_ranking_roadmap: "Plan out the execution of ideas based on Q1, Q2, Q3, Q4.",
  evaluation_method_info_ranking_innovation_matrix: "Structure your ideas by organizing them according to how sustaining, disruptive, incremental, and radical they are.",
  evaluation_method_info_matrix_effort_vs_impact: "By seeing where your ideas land on an effort vs impact grid you will be able to determine which ideas are most worth advancing.",
  evaluation_method_info_matrix_value_vs_time: "A widely used tool in the strategic field, McKinsey's 3 horizons model helps you discuss growth strategy in relation to the core business over the months and years ahead.",
  evaluation_method_info_matrix_difficult_vs_risk: "Is difficulty and risk always to be avoided? No. But it is always important to know what you are getting yourself into. This grid will give you a framwork to thoughtfully consider the difficulty and risk associated with your ideas.",
  evaluation_method_info_matrix_effort_vs_reward: "Plot where this idea sits on an effort vs impact chart.",
  evaluation_method_info_matrix_need_vs_want: "What ideas are nice to have and what ideas are crucial for the success of your problem statement?",
  evaluation_method_info_venn_sector_reliance: "Plot where you think this idea sits in the intersection of Technology, Business, and Design.",
  evaluation_method_info_venn_lenses_of_innovation: 'Often called the "innovation Sweet Spot". Compare your ideas by using this 3-circle venn diagram to compare Viability, Feasibility, and Desirability.',
  evaluation_method_info_rate_consumer_industrial: "Choose where this idea sits on the Consumer to Industrial scale.",
  evaluation_method_info_rate_likert: "Choose where you think this idea sits on the likert scale.",
  evaluation_method_info_rate_abstract_literal: "Choose where this idea sits on the Abstract to Literal scale.",
  evaluation_method_info_rate_fragmented_completed: "Fragmented Completed", // TODO
  evaluation_method_info_rate_idea_development_stage: "Analyze the level of development of ideas by using this template.",
  andXMore: "and {{count}} more...",
  pleaseSelectEvaluationMethods: "Please select evaluation methods",
  evaluate: "Evaluate",
  pleaseEnterVoteLimit: "Please enter a vote limit",
  theStartDateHasAlreadyPassed: "The start date has already passed",
  theStageStartsInLessThanMinute: "The {{stageName}} stage starts in less than a minute",
  theChallengeEndsInLessThanMinute: "The challenge ends in less than a minute",
  untitled: "Untitled",
  roiEstimates: "ROI Estimates",
  roiEstimate: "ROI Estimate",
  roiEstimatesInfo: "Leverage the ROI calculator for quickly assessing the value your ideas are providing.",
  estimatedROI: "Estimated ROI",
  addROIEstimate: "Add ROI Estimate",
  roiEstimateNotesEntryExample: "This is an example of the notes entry.",
  entityDeleted: "{{entity}} deleted",
  entitySaved: "{{entity}} saved",
  estimatedBenefit: "Estimated Benefit",
  estimatedUpfrontCost: "Estimated Upfront Cost",
  estimatedRecurringCost: "Estimated Recurring Cost",
  defaultCostOfCapital: "Default Cost of Capital",
  otherNotes: "Other Notes",
  estimatedROINotesPlaceholder: "E.g. There are intangible benefits to moving forward with this...",
  required: "Required",
  exceededTheLimitOfX: "Exceeded the limit of {{limitStr}}",
  confirmDelete: "Confirm delete",
  hideMainNavigation: "Hide the navigation and branding bar",
  hideViewSwitcher: "Hide the extra sharable views (canvas, presentation, and spreadsheet)",
  widgetIcon: "Widget Icon",
  useDefaultLogo: "Use Default Logo",
  invalidColor: "Invalid color",
  submitNew: "Submit New",
  reconnecting: "Reconnecting...",
  redirecting: "Redirecting...",
  formHeader: "Form Header",
  formBody: "Form Body",
  expandAll: "Expand All",
  collapseAll: "Collapse All",
  expandAllEvaluationButtons: "Expand all evaluation buttons",
  collapseAllEvaluationButtons: "Collapse all evaluation buttons",
  allowPublicSignUpAndSignIn: "Allow Public Sign-Up & Sign-In",
  mustUseIinvitedEmailAddress: "Please note that you must use the email address where your invite was sent to sign up",
  mustUseEmailAddressEndingWithX: "Please note that you must use the email address ending with @{{domain}}",
  alreadySignedUp: "Already signed up?",
  logInHere: "Log in here",
  haveNotSignedUpYet: "Haven't signed up yet?",
  signUpHere: "Sign up here",
  spreadsheet: "Spreadsheet",
  spreadsheetView: "Spreadsheet View",
  switchToXView: "Switch to {{viewName}} view",
  editSpreadsheet: "Edit Spreadsheet",
  viewSpreadsheet: "View Spreadsheet",
  weAreGeneratingYourSpreadsheet: "We are generating your spreadsheet, this may take up to 10 seconds.",
  embeddedGoogleSheet: "Embedded Google Sheet",
  createGoogleSheet: "Create Google Sheet",
  clickToExportToGoogleSheet: "Click to export to Google Sheet",
  spreadsheetIsUpdated: "The spreadsheet is updated",
  whenTheExportIsDone: "When the export is done",
  confirming: "Confirming",
  pendingEvaluation: "Pending Evaluation",
  pendingEvaluation_other: "Pending Evaluations",
  mustBeAtLeast: "Must be at least {{limitStr}}",
  xIsBeingUsed: "{{name}} is being used.",
  areYouSureToRemoveIt: "Are you sure you want to remove it?",
  workspaceLogo: "Workspace Logo",
  inventValueLogo: "Invent Value Logo",
  clearFilter: "Clear Filter",
  methods: "Methods",
  boardSettings: "Board Settings",
  enterStatusNameHere: "Enter status name here",
  xVotesLeft: "{{count}} vote left",
  xVotesLeft_other: "{{count}} votes left",
  youHaveXVotesLeft: "You have {{count}} vote left",
  youHaveXVotesLeft_other: "You have {{count}} votes left",
  totalXVotesSubmitted: "Total {{count}} vote submitted",
  totalXVotesSubmitted_other: "Total {{count}} votes submitted",
  setYourIdeaUpForSuccess: "Set your idea up for success!",
  richMedia: "Rich Media",
  tags: "Tags",
  evaluated: "Evaluated",
  xPeopleEvaluated: "{{count}} person evaluated.",
  xPeopleEvaluated_other: "{{count}} people evaluated.",
  confirmation: "Confirmation",
  voter: "Voter",
  voter_other: "Voters",
  removeTheProgressBar: "Remove the Progress Bar",
  removeTheProgressBarDescription: "Don't want to see the progress bar?",
  removeTheProgressBarSubDescription: "Please select your preference.",
  removePermanently: "Never show again",
  removeProgressBarPermanentlyDescription: "The progress bar will no longer be shown in any board and challenge afterward.",
  dontShowThisTime: "Don't show only this time",
  xRemoveProgressBarIndividuallyDescription: "The progress bar will no longer be shown in this particular {{moduleName}}. It will present in the other {{otherModuleName}}.",
  theProgressBarIsRemoved: "The progress bar is removed",
  beTheFirstToEvaluate: "Be the first to evaluate.",
  itIsWaitingForYouToEvaluate: "It's waiting for you to evaluate.",
  noVotesLeft: "No votes left",
  generateIdeas: "Generate Ideas",
  generateIdeasWithAI: "Generate Ideas with AI",
  theContext: "The Context",
  enterSomeContext: "Enter some context",
  numberOfIdeasToGenerate: "Number of Ideas to Generate",
  xIdeasAreBeingGeneratedWithAI: "{{count}} idea is being generated with AI",
  xIdeasAreBeingGeneratedWithAI_other: "{{count}} ideas are being generated with AI",
  generatedByAI: "Generated by AI",
  evaluationSummaryEnabled: "Enable Evaluation Summary",
  autoGenerateIdeasBaseOnExisting: "Automatically generate new ideas based on existing {{count}} idea",
  autoGenerateIdeasBaseOnExisting_other: "Automatically generate new ideas based on existing {{count}} ideas",
  manuallyEnterContext: "Manually enter context to generate ideas",
  noExistingIdeasAvailable: "No existing ideas available",
  chooseImageStyle: "Choose image style",
  photographic: "Photographic",
  illustrative: "Illustrative",
  autoGenOneMoreIdea: "Auto-generate one more idea with every user contribution",
  autoGenOneMoreIdeaWithAI: "Automatically generate an additional idea with AI",
  autoGenOneMoreIdeaWithAIDescription: "AI will automatically generate one more idea based on the context of your input, providing extra inspiration to build upon your contribution.",
};
